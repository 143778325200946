body {
    margin: 0;
    width: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

 .container{
     margin:20px auto;
     width:1200px;
 }
p {
    width: 85%;
    margin: 5px auto;
    font-size:1.5em;
}
p.error{
    margin:25px auto;
    color:firebrick;
}

.loading {
    width: 85%;
    margin: 30px auto;
}

iframe {
    width: 90%;
    height: 600px;
    margin:30px auto;
    display:block;
    border-radius:6px;
}

.logo-gallery {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 90%;
 
    margin: 30px auto;
}

.logo-gallery img{
    width:100%;
}

@media only screen and (max-width: 844px) {
    .container {
        margin: 20px auto;
        width: 750px;
    }


    iframe {
        width: 80%;
        height: 400px;
    }

    .logo-gallery {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 700px;
     }
}


@media only screen and (max-width: 600px) {
    .container {
        margin: 20px auto;
        width: 500px;
    }

  

    iframe {
        width: 80%;
        height: 400px;
    }

    .logo-gallery {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 500px;
    }
}

@media only screen and (max-width: 390px) {
    .container {
        margin: 20px auto;
        width: 350px;
    }

    iframe {
        width: 95%;
         
    }

    .logo-gallery {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 95%;
    }
}